import './App.css';
import { gsap } from "gsap";
import 'react-slideshow-image/dist/styles.css'
    
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { TextPlugin } from "gsap/TextPlugin";
import { EasePack } from "gsap/EasePack";

/* The following plugins are Club GSAP perks */
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import { ScrambleTextPlugin } from "gsap/ScrambleTextPlugin";
import { SplitText } from "gsap/SplitText";
import {GSDevTools } from "gsap/GSDevTools";
import {MorphSVGPlugin } from "gsap/MorphSVGPlugin";
import { Flip } from "gsap/Flip";
import { First } from './components/first';
import { IntlProvider } from 'react-intl';
import './translations/i18n';


gsap.registerPlugin(ScrollTrigger,ScrollToPlugin,TextPlugin,DrawSVGPlugin,ScrollSmoother,ScrambleTextPlugin,SplitText, EasePack, Flip, GSDevTools, MorphSVGPlugin);
// @ts-ignore
export var smoother;


function App() {
  return (
    <div id="smooth-wrapper" className="App">
      <First/>
    </div>
  );
}

export default App;
