import i18n from "i18next";
import { initReactI18next } from "react-i18next";
function getLang() {
  if (navigator.languages != undefined) 
    return navigator.languages[0]; 
  return navigator.language;
}
const lang = () => {
  const cur = getLang();
  if (cur === 'ru-RU') return 'ru';
  if (cur === 'uk-UA') return 'ua';
  if (cur === 'nl-NL') return 'nl';
  return 'en';
}

const resources = {
  en: {
    translation: {
      "welcome": "We don't just create websites,",
      "welcome2": "We implement unique designs that will leave your competitors in the shade.",
      "formHeader": "Leave a request to see how cool your site can look",
      "name": "Name",
      "phone": "Phone number or telegram",
      "required": "Required field",
      "submit": "Submit",
      "thank": "Thank you",
      "touch": "We will contact you!" 
    }
  },
  ru: {
    translation: {
      "welcome": "Мы создаем не просто сайты,",
      "welcome2": "мы воплощаем уникальные дизайны, после которых ваши конкуренты будут в тени.",
      "formHeader": "Оставляйте заявку, чтобы убедиться, насколько круто может выглядеть ваш сайт",
      "name": "Имя",
      "phone": "Телефон или телеграм",
      "required": "Поле обязательно",
      "submit": "Отправить",
      "thank": "спасибо",
      "touch": "мы с вами свяжемся!" 
    }
  },
  ua: {
    translation: {
      "welcome": "Ми створюємо не просто сайти",
      "welcome2": "котрі змусять ваших конкурентів бути у тіні.",
      "formHeader": "Залишайте заявку, аби побачити, як круто може виглядати ваш сайт",
      "name": "Імʼя",
      "phone": "Номер телефону або телеграм",
      "required": "Обовʼязково до заповнення",
      "submit": "Відправити",
      "thank": "Дякую",
      "touch": "ми з вами зв'яжемося!" 
    }
  },
  nl: {
    translation: {
      "welcome": "Wij maken meer dan alleen websites,",
      "welcome2": "we implementeren unieke ontwerpen die uw concurrenten in de schaduw zullen zetten.",
      "formHeader": "Dien een verzoek in om te zien hoe cool uw site eruit kan zien,",
      "name": "Naam",
      "phone": "Telefoon of telegram",
      "required": "Het veld is verplicht",
      "submit": "Indienen",
      "thank": "Bedankt",
      "touch": "we nemen contact met je op!" 
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: lang(),

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;
