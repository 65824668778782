import gsap from 'gsap';
import { useLayoutEffect } from 'react';
import styles from './first.module.css';
import { Flip } from "gsap/Flip";
import { SplitText } from "gsap/SplitText";
import {ReactComponent as Lamp} from './assets/lamp_morph.svg'
import {ReactComponent as Net} from './assets/net.svg'
import { Form } from './form';
import ReactDOM from 'react-dom';
const step2 = (cb: any) => {
  const tl = gsap.timeline();
  const bright = Flip.getState('#bright');
  const terrific = Flip.getState('#terrific');
  const exquisite = Flip.getState('#exquisite');
  const astonishing = Flip.getState('#astonishing');
  cb();
  tl.add(Flip.from(bright, {duration: 1, simple: true, ease: "elastic.out(0.3,0.3)"}));
  tl.add(Flip.from(terrific, {duration: 1, simple: true, ease: "elastic.out(0.3,0.3)"}), '<0%');
  tl.add(Flip.from(exquisite, {duration: 1,simple: true,  ease: "elastic.out(0.3,0.3)"}), '<0%');
  tl.add(Flip.from(astonishing, {duration: 1,simple: true,  ease: "elastic.out(0.3,0.3)"}), '<0%');
  return tl;
}
const flip = () => {
  const tl = gsap.timeline();
  const bright2 = Flip.getState('#bright');
  const terrific2 = Flip.getState('#terrific');
  const exquisite2 = Flip.getState('#exquisite');
  const astonishing2 = Flip.getState('#astonishing');
  document.getElementById('container')?.classList.remove(styles.removeGap);
  document.getElementById('container')?.classList.add(styles.moveTop);
  const bright = Flip.getState('#text1');
  const terrific = Flip.getState('#text2');
  const exquisite = Flip.getState('#text3');
  const astonishing = Flip.getState('#text4');
  document.getElementById('container')?.classList.add(styles.gapLogo);
  tl.add(Flip.from(bright, {duration: 1, simple: true, ease: "elastic.out(0.3,0.3)"}));
  tl.add(Flip.from(terrific, {duration: 1, simple: true, ease: "elastic.out(0.3,0.3)"}), '<0%');
  tl.add(Flip.from(exquisite, {duration: 1,simple: true,  ease: "elastic.out(0.3,0.3)"}), '<0%');
  tl.add(Flip.from(astonishing, {duration: 1,simple: true,  ease: "elastic.out(0.3,0.3)"}), '<0%');
  tl.add(Flip.from(bright2, {duration: 1, simple: true, ease: "elastic.out(0.3,0.3)"}), '<0%');
  tl.add(Flip.from(terrific2, {duration: 1, simple: true, ease: "elastic.out(0.3,0.3)"}), '<0%');
  tl.add(Flip.from(exquisite2, {duration: 1,simple: true,  ease: "elastic.out(0.3,0.3)"}), '<0%');
  tl.add(Flip.from(astonishing2, {duration: 1,simple: true,  ease: "elastic.out(0.3,0.3)"}), '<0%');
  return tl;
}
const step1 = () => {
  const tl = gsap.timeline();
  tl.from('#bright', {duration: 0.5,autoAlpha: 0});
  tl.from('#terrific', {duration: 0.5,autoAlpha: 0});
  tl.from('#exquisite', {duration: 0.5,autoAlpha: 0});
  tl.from('#astonishing', {duration: 0.5,autoAlpha: 0});
  return tl;
}
const step3 = () => {
  return gsap.timeline()
    .to("#text1", {duration: 1, text: 'b'}, '<0%')
    .to("#text2", {duration: 1, text: 'e'}, '<0%')
    .to("#text3", {duration: 1, text: 't'}, '<0%')
    .to("#text4", {duration: 1, text: 'a'}, '<0%')
    .to("#bright", {duration: 1, width: '5%', height: '4rem'}, '<0%')
    .to("#exquisite", {duration: 1, width: '5%', height: '4rem'}, '<0%')
    .to("#terrific", {duration: 1, width: '5%', height: '4rem'}, '<0%')
    .to("#astonishing", {duration: 1, width: '5%', height: '4rem'}, '<0%')
}
const step4 = () => {
  const tl = gsap.timeline({
    onComplete: () => {
        const split = new SplitText("#formText", {type: "words"}).words;
        const split2 = new SplitText("#shadow", {type: "words"}).words;
        const target = split.find(word => ['круто', 'cool'].includes(word.innerHTML));
        const target2 = split2.find(word => ['тени.', 'shade.', 'schaduw'].includes(word.innerHTML));
        const paths = gsap.utils.toArray([ "#city path", "#city rect", "#city circle"])
        gsap.set(paths, {autoAlpha: '1'})
        // @ts-ignore
        ReactDOM.render(<><span>{target2?.innerHTML}</span><Lamp id="lampReversed" className={styles.lampReversed}/></>, target2);
        // @ts-ignore
        gsap.to(target, {duration: 2, background: 'black', color: 'yellow'})
        //@ts-ignore
        gsap.to(target2, {duration: 2, background: '#383737', color: 'white'})
        const tl = gsap.timeline();
        tl.from("#lamp", {duration:3,drawSVG: 0})
        tl.to(".light", {duration:3, strokeWidth: '1rem', stroke: '#FFF500'})
        tl.to(`.${styles.net}`, {duration:0, autoAlpha: 1})
        tl.from("#net", {duration:3, drawSVG: 0, stagger: 0.5})
        tl.from(paths, {duration:5,drawSVG: 0, stagger: 0.1}, '-=10')
        tl.set("#wheel", {transformOrigin: '50% 50%'})
        tl.to("#wheel", {duration:100, rotate: 360, repeat: -1})
      }
    })
    .to("#lamp", {duration:0,autoAlpha: 1})
    .from("#lamp", {duration:3,drawSVG: 0})
    .to(".turnOn", {duration:0.1, y: 20, ease: "bounce.out", yoyo: true, repeat: 1})
    .to(".light", {duration:3, strokeWidth: '1rem', stroke: '#FFF500'})
    .to("#rootContainer", {duration: 3, background: '#DBDBDB'}, '<0%')
    .to("#lamp", {duration:3,autoAlpha: 0}, '<0%')
    .to("#form", {display: "flex"}, '<0%')
  return tl;
}
function progressiveBuild() {
  let data = Array.from(arguments),
    i = 0,
    tl = gsap.timeline({
      onComplete: function () {
        let isNum = typeof data[i] === "number",
          delay = isNum ? data[i++] : 0,
          func = data[i++];
        typeof func === "function" && tl.add(func(), "+=" + delay);
      },
    });
  // @ts-ignore
  tl.vars.onComplete();
  return tl;
}
export const First = () => {
  useLayoutEffect(() => {
    const paths = gsap.utils.toArray([ "#city path", "#city rect", "#city circle"])
    gsap.set(paths, {fill: 'transparent', autoAlpha: 0})

    progressiveBuild(
      // @ts-ignore
      step1,
      () => step2(() => document.getElementById('container')?.classList.add(styles.containerNext)) as any,
      () => step2(() => document.getElementById('container')?.classList.add(styles.removeGap)) as any,
      step3,
      () => step2(() => document.getElementById('container')?.classList.add(styles.container4)) as any,
      flip,
      step4,
    )
    }, 
  [])
    return <div id="rootContainer" className={styles.rootContainer}><div id="container" className={styles.container}>
      <div id="bright" className={styles.bright}><span id="text1">Bright</span></div>
      <div id="exquisite" className={styles.exquisite}><span id="text2">exclusive</span></div>
      <div id="terrific" className={styles.terrific}><span id="text3">terrific</span></div>
      <div id="astonishing" className={styles.astonishing}><span id="text4">astonishing</span></div>
      <Lamp className={styles.lamp}/>
      <Net className={styles.net}/>
      </div>
          <Form/>
      </div>
 }
