import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
//@ts-ignore
import Popup from 'react-popup';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
<>
    <Popup closeBtn={true}/>
    <App />
    </>
);

