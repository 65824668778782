import styles from './form.module.css'
import {ReactComponent as City} from './assets/city.svg';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { useState } from 'react';
import gsap from 'gsap';

export const Form = () => {
  const { t } = useTranslation();
  const [submitted, setSubmitted] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm<{name: string, phone:string, descr: string}>();
  const onSubmit = ( data: any ) => {
    if (submitted) return;
    setSubmitted(true);
    const tl = gsap.timeline();
    tl.to("#submitButton", {
      duration: 3, 
      scrambleText: t('thank')
    });
    tl.to("#submitButton", {
      duration: 3, 
      scrambleText: t('touch')
    });
    fetch('https://eu-api.jotform.com/form/233315077954056/submissions?apiKey=d7ec06eef1adbf94c25ce891ef28a407', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: `submission[4]=${data.name}&submission[5]=${data.phone}`
    });
  }
  return <><div id="form" className={styles.container}>
    <span className={styles.header}>{t('welcome')}</span>
    <span id="shadow" className={styles.header2}>{t('welcome2')}</span>
    <div className={styles.formContainer}>
    <span id="formText">{t("formHeader")}</span>
    <input className={styles.formInput} {...register("name", { required: true })} placeholder={t("name")}></input>
    {errors.name && <span style={{color: 'red'}}>{t("required")}</span>}
    <input autoComplete='mobile tel' type="tel" className={styles.formInput} {...register("phone", { required: true })} placeholder={t("phone")}></input>
    {errors.phone && <span style={{color: 'red'}}>{t("required")}</span>}
    <div className={styles.buttonContainer}>
      <button id="submitButton" className={styles.button} onClick={handleSubmit(onSubmit)}>{t("submit")}</button>
    </div>
    </div>
    </div>
    <City className={styles.city}/>
    </>
  }
